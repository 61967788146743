.divFilterTitle {
  width: 240px;
  height: 42px;
  position: absolute;
  left: 30px;
  top: 30px;
}
.spanTitle {
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
}
.filterContainer {
  position: relative;
  width: auto;
  height: auto;
  background: #005eb8;
  box-shadow: -5px 0px 25px rgba(0, 0, 0, 0.25);
}
.dividerStyle {
  position: absolute;
  width: 84%;
  left: 30px;
  top: 72px;
  border: 1px solid #ffffff;
}
.filterAction {
  display: flex;
  padding: 20px 10px;
  gap: 2px;
  width: 100%;
  bottom: 1px;
}
.defaultBtn {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.primaryBtn {
  gap: 10px;
  width: 50%;
  height: 45px;
  background: #005eb8;
  border-radius: 5px;
}
.selectedFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 45px;
  float: "left";
  width: "85%";
}
.selectedFilterItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  height: 45px;
  background: #f2f2f2;
  border: 1px solid #cccccc;
  border-radius: 20px;
}
.filterIcon {
  width: 45px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.filterClearBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 132px;
  height: 45px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #4d4d4d;
}
.ms-Panel-commands {
  display: none !important;
}
.ms-Panel-content {
  padding: 0px !important;
}
.ms-Panel-main {
  background: #005eb8;
}
.filterCheckbox {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 1px;
}
.filterLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.filterItem {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 5px;
}
