.leaflet-control {
  &-zoom-in,
  &-zoom-out {
    color: $sky60 !important;
  }
}

.leaflet-tooltip-top {
  background-color: $gray600;
  color: $white;
}
