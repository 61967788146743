.amd {
  &-maincontainer {
    height: calc(100% - 120px);
    overflow: hidden auto;
    padding: pxToRem(30) 0;

    &--teams {
      height: calc(100% - 60px);
    }
  }
  &-main {
    width: pxToRem(1206);
    margin: auto;
    position: relative;
  }

  &-main-ipad-landscape {
    width: pxToRem(1206);
    margin: auto;
    position: relative;
  }

  @media screen and (max-width: 1440px) {
    &-main {
      width: pxToRem(1100);
    }
  }

  @media screen and (max-width: 1366px) {
    &-main {
      width: pxToRem(943);
    }
  }
}
