#{$global}-list {
  margin: 0;
  padding: 0;
  height: 100%;
  width: calc(100% - 8px);
  padding-right: pxToRem(8);
  overflow-y: auto;
  list-style-type: none;
  &__item {
    display: block;
    padding: pxToRem(20) 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &-desc {
    display: inline-block;
    &--w138 {
      width: 138px;
    }
    &__heading {
      color: $gray800;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      padding: 5px 0;
    }
    &__dt {
      font-size: 13px;
      line-height: 16px;
      color: $gray600;
      display: inline-block;
      padding: 5px 0;
      width: 50%;
    }
    &__dd {
      color: $gray700;
      font-weight: 700;
      line-height: 16px;
      display: inline-block;
      padding: 5px 0;
      text-align: right;
      width: 50%;
      margin: 0;
    }
  }
}
