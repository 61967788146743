$spacer: 8px;
$small_spacer: 5px;
$baseUnit: 14;
$global: ".amd";

$white: white;
$black: black;
$gray200: #cccccc;
$gray600: #666666;
$gray700: #4d4d4d;
$gray800: #333333;
$gray900: #1a1a1a;
$primary: #005eb8;
$darkPrimary: #004485;
$brown: #9e6900;
$cherry: #ce0058;
$dangerbg: #fedfdf;
$warningbg: #fef4e4;
$safebg: #e0f4ea;
$red: #c60000;
$darkorange: #e95326;
$green: #008540;
$sky60: #0c66e1;
