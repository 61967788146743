#{$global}-container {
  &-vflex {
    display: flex;
    flex-flow: column;
    &--center {
      justify-content: center;
    }
  }

  &-vcenter {
    display: flex;
    align-items: center;
  }
  &-vcolumn {
    flex-direction: column;
  }
  &-vstart {
    display: flex;
    align-items: flex-start;
  }
  &-vend {
    display: flex;
    align-items: flex-end;
  }
  &-vspacebtwn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-hspacebtwn {
    display: flex;
    justify-content: space-between;
  }
  &-haround {
    display: flex;
    justify-content: space-around;
  }
  &-hstart {
    display: flex;
    justify-content: flex-start;
  }
  &-hcenter {
    display: flex;
    justify-content: center;
  }
  &-hend {
    display: flex;
    justify-content: flex-end;
  }
  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
