.ui-carousel {
  position: relative;
  margin-top: -37px;
  height: 232px;
}

.ui-carrouselpaddles_container {
  position: absolute;
  bottom: -63px;
  left: 7px;
  width: calc(100% - 16px);
  display: flex;
  justify-content: space-between;
}

.ui-carousel__paddle.ui-carousel__paddleprevious,
.ui-carousel__paddle.ui-carousel__paddlenext {
  text-decoration: none;
  height: 12px;
  background-color: transparent;
  color: black;
  outline: transparent;
  display: inline-block;
  border: none;
  cursor: pointer;
}

.ui-carousel__paddle.ui-carousel__paddlenext
  .ui-box.ui-carousel__paddle__content {
  display: inline-block;
  height: 12px;
  width: 7px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M0.732712 0.206944C1.03263 -0.0787473 1.50737 -0.0672107 1.79306 0.232712L6.79439 5.48318C7.07032 5.77285 7.07032 6.22809 6.79439 6.51776L1.79306 11.7682C1.50737 12.0681 1.03263 12.0797 0.732712 11.794C0.432789 11.5083 0.421253 11.0336 0.706944 10.7336L5.21553 6.00047L0.706944 1.26729C0.421253 0.967369 0.432789 0.492635 0.732712 0.206944Z' fill='%234D4D4D'/%3E%3C/svg%3E");
}

.ui-carousel__paddle.ui-carousel__paddleprevious
  .ui-box.ui-carousel__paddle__content {
  display: inline-block;
  height: 12px;
  width: 7px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M6.26765 11.793C5.96773 12.0787 5.49299 12.0672 5.2073 11.7672L0.205966 6.51678C-0.0699574 6.22711 -0.0699574 5.77187 0.205966 5.4822L5.2073 0.231735C5.49299 -0.0681872 5.96773 -0.0797238 6.26765 0.205968C6.56757 0.491659 6.57911 0.966392 6.29342 1.26631L1.78483 5.99949L6.29342 10.7327C6.57911 11.0326 6.56757 11.5073 6.26765 11.793Z' fill='%234D4D4D'/%3E%3C/svg%3E");
}

.ui-carousel__navigation.ui-carousel__navigation {
  list-style-type: none;
  min-height: 18px;
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  width: calc(100% - 32px);
}

.ui-carousel__navigationitem {
  display: inline-block;
}

.ui-carousel__navigationitem:not(:last-child) {
  margin-right: 5px;
}

.ui-box.ui-carousel__navigationitem__indicator {
  background: #d9d9d9;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.ui-carousel__navigationitem[aria-selected="true"]
  .ui-carousel__navigationitem__indicator {
  background: #005eb8;
}

.ui-carousel__itemscontainer {
  height: 290px;
  margin-bottom: -6px;
}
