.amd {
  &-flyout {
    width: 24px;
    max-height: pxToRem(428);
    padding: pxToRem(18);
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: fixed;
    right: 17px;
    &--app {
      top: pxToRem(90);
    }
    &--teams {
      top: pxToRem(30);
    }

    @media screen and (max-width: 1132px) {
      display: none;
    }
  }
}
