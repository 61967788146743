#{$global}-heading {
  &1 {
    font-family: Amadeus, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 55px;
    font-weight: 400;
    color: #005eb8;
    margin-top: 0;
  }
}
