#{$global}-section {
  &-shadow {
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: pxToRem(20);

    &__header {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      color: black;
      margin-bottom: pxToRem(15);

      &--lg {
        font-size: 32px;
        line-height: 40px;
        text-transform: none;
        font-weight: 400;
      }
    }
  }
  &--br {
    border: 1px solid #cccccc;
  }
  &--br-b {
    border-bottom: 1px solid #cccccc;
  }
}
