@for $i from 0 through 20 {
  .ma-#{$i} {
    margin: $i * $spacer;
  }
  .mt-#{$i} {
    margin-top: $i * $spacer;
  }
  .mb-#{$i} {
    margin-bottom: $i * $spacer !important;
  }
  .ml-#{$i} {
    margin-left: $i * $spacer !important;
  }
  .mr-#{$i} {
    margin-right: $i * $spacer !important;
  }
  .mx-#{$i} {
    margin-left: $i * $spacer;
    margin-right: $i * $spacer;
  }
  .my-#{$i} {
    margin-top: $i * $spacer;
    margin-bottom: $i * $spacer;
  }

  .ma-n#{$i} {
    margin: -$i * $spacer;
  }
  .mt-n#{$i} {
    margin-top: -$i * $spacer;
  }
  .mb-n#{$i} {
    margin-bottom: -$i * $spacer;
  }
  .ml-n#{$i} {
    margin-left: -$i * $spacer;
  }
  .mr-n#{$i} {
    margin-right: -$i * $spacer;
  }
  .mx-n#{$i} {
    margin-left: -$i * $spacer;
    margin-right: -$i * $spacer;
  }
  .my-n#{$i} {
    margin-top: -$i * $spacer;
    margin-bottom: -$i * $spacer;
  }

  .ma-sm-#{$i} {
    margin: $i * $small_spacer;
  }
  .mt-sm-#{$i} {
    margin-top: $i * $small_spacer;
  }
  .mt-sm-n#{$i} {
    margin-top: -$i * $small_spacer;
  }
  .mb-sm-#{$i} {
    margin-bottom: $i * $small_spacer;
  }
  .ml-sm-#{$i} {
    margin-left: $i * $small_spacer;
  }
  .mr-sm-#{$i} {
    margin-right: $i * $small_spacer;
  }
  .mx-sm-#{$i} {
    margin-left: $i * $small_spacer;
    margin-right: $i * $small_spacer;
  }
  .my-sm-#{$i} {
    margin-top: $i * $small_spacer;
    margin-bottom: $i * $small_spacer;
  }

  .pa-#{$i} {
    padding: $i * $spacer;
  }
  .pt-#{$i} {
    padding-top: $i * $spacer;
  }
  .pb-#{$i} {
    padding-bottom: $i * $spacer;
  }
  .pl-#{$i} {
    padding-left: $i * $spacer;
  }
  .pr-#{$i} {
    padding-right: $i * $spacer;
  }
  .px-#{$i} {
    padding-left: $i * $spacer;
    padding-right: $i * $spacer;
  }
  .py-#{$i} {
    padding-top: $i * $spacer;
    padding-bottom: $i * $spacer;
  }

  .pa-sm-#{$i} {
    padding: $i * $small_spacer;
  }
  .pt-sm-#{$i} {
    padding-top: $i * $small_spacer;
  }
  .pb-sm-#{$i} {
    padding-bottom: $i * $small_spacer;
  }
  .pl-sm-#{$i} {
    padding-left: $i * $small_spacer;
  }
  .pr-sm-#{$i} {
    padding-right: $i * $small_spacer;
  }
  .px-sm-#{$i} {
    padding-left: $i * $small_spacer;
    padding-right: $i * $small_spacer;
  }
  .py-sm-#{$i} {
    padding-top: $i * $small_spacer;
    padding-bottom: $i * $small_spacer;
  }
}

.float {
  &-l {
    float: left;
  }

  &-r {
    float: right;
  }

  &-n {
    float: none;
  }
}

.text {
  &-r {
    text-align: right;
  }
  &-l {
    text-align: left;
  }
  &-c {
    text-align: center;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-d-none {
    text-decoration: none;
  }
}

.margin {
  &-auto {
    margin: 0 auto;
  }
}

.wb {
  &-all {
    word-break: break-all;
  }
  &-word {
    word-break: break-word;
  }
}

.d {
  &-block {
    display: block;
  }

  &-none {
    display: none;
  }

  &-iblock {
    display: inline-block;
  }
  &-iflex {
    display: inline-flex;
  }
  &-itable {
    display: inline-table;
  }
  &-flex {
    display: flex;

    &--wrap {
      flex-wrap: wrap;
    }
  }
}

.ai {
  &-flex-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
}

.jc {
  &-start {
    justify-content: flex-start;
  }
  &-spacebtwn {
    justify-content: space-between;
  }
  &-end {
    justify-content: end;
  }
}

.as {
  &-center {
    align-self: center;
  }
  &-end {
    align-self: self-end;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.lh {
  &-32 {
    line-height: 32px;
  }
}

.bg {
  &--white {
    background: white;
  }

  &--transparent {
    background: transparent;
  }

  &--danger {
    background: $dangerbg;
  }

  &--warning {
    background: $warningbg;
  }

  &--safe {
    background: $safebg;
  }
}

.pos {
  &-rel {
    position: relative;
  }
  &-abs {
    position: absolute;
  }
}

.fw {
  &-bold {
    font-weight: bold;
  }
  &-normal {
    font-weight: normal;
  }
  &-250 {
    font-weight: 250;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
}
.fc {
  &--white {
    color: white;
  }
  &--black {
    color: black;
  }
  &--gray200 {
    color: $gray200;
  }
  &--gray600 {
    color: $gray600;
  }
  &--gray700 {
    color: $gray700;
  }
  &--gray800 {
    color: $gray800;
  }
  &--gray900 {
    color: $gray900;
  }
  &--primary {
    color: $primary;
  }
  &--darkprimary {
    color: $darkPrimary;
  }
  &--brown {
    color: $brown;
  }
  &--cherry {
    color: $cherry;
  }
  &--red {
    color: $red;
  }
  &--green {
    color: $green;
  }
  &--darkorange {
    color: $darkorange;
  }
}

.br {
  &-b {
    &-none {
      border-bottom: none;
    }
    &-1 {
      border-bottom: 1px solid;
    }
  }
  &-t {
    &-none {
      border-bottom: none;
    }
  }
  &-none {
    border: none;
  }
}

.h {
  &-100 {
    height: 100%;
  }
  &-10 {
    height: 10%;
  }
}

.w {
  &-100 {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }
  &-25 {
    width: 25%;
  }
  &-10 {
    width: 10%;
  }
  &-15 {
    width: 15%;
  }
  &-20 {
    width: 20%;
  }
  &-30 {
    width: 30%;
  }
  &-35 {
    width: 35%;
  }
  &-40 {
    width: 40%;
  }
  &-70 {
    width: 70%;
  }
  &-80 {
    width: 80%;
  }
  &-90 {
    width: 90%;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-visible {
    overflow: visible;
  }
  &-auto {
    overflow: auto;
  }
  &-scroll {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 10px;
    }
  }
  &-x {
    overflow-x: auto !important;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 10px;
    }
  }
  &-y {
    overflow-y: auto;
  }
}

.pmiddle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-default {
    cursor: default;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.flex {
  &-grow {
    &-1 {
      flex-grow: 1;
    }
    &-2 {
      flex-grow: 2;
    }
    &-3 {
      flex-grow: 3;
    }
    &-4 {
      flex-grow: 4;
    }
    &-5 {
      flex-grow: 5;
    }
    &-6 {
      flex-grow: 6;
    }
  }
  &-1 {
    flex: 1;
  }
  &-2 {
    flex: 2;
  }
  &-3 {
    flex: 3;
  }
  &-4 {
    flex: 4;
  }
  &-5 {
    flex: 5;
  }
  &-6 {
    flex: 6;
  }
  &-flow {
    &-colrev {
      flex-flow: column-reverse;
    }
    &-rowrev {
      flex-flow: row-reverse;
    }
  }
}

.ff {
  &-amd {
    font-family: Amadeus, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}
.fs {
  &-12 {
    font-size: 12px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-16 {
    font-size: 16px;
  }
  &-18 {
    font-size: 18px;
  }
  &-20 {
    font-size: 20px;
  }
  &-21 {
    font-size: 21px;
  }
  &-22 {
    font-size: 22px;
  }
  &-24 {
    font-size: 24px;
  }
  &-26 {
    font-size: 26px;
  }
  &-28 {
    font-size: 28px;
  }
  &-36 {
    font-size: 36px;
  }
  &-40 {
    font-size: 40px;
  }
}

.legend {
  &-square {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    display: inline-block;
  }
}

.divider {
  &-v {
    &-ltgrey {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
    &-grey {
      border-left: 1px solid #ccc;

      &--hiddenXlDown {
        @media screen and (max-width: 1366px) {
          border: none;
        }
      }

      &--rt {
        border-right: 1px solid #ccc;
      }
    }
  }
}

.link {
  &-br {
    padding: 6px 11px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
  }
}

.clear {
  &-left {
    clear: left;
  }
  &-right {
    clear: right;
  }
  &-both {
    clear: both;
  }
}

.li-type {
  &-none {
    list-style-type: none;
  }
}
.hop-legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.hop-legend-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.active {
  background-color: #e6eff8;
}
.gap-5 {
  gap: 5px;
}
.kit-2v {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
}
.hop-kpi-detail {
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.progressbar-border {
  border-left: "1px solid #000";
  padding: "2px 0";
}

.table-header {
  display: -webkit-inline-box;
  position: absolute;
  inset: 0px;
}
