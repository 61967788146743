#{$global}-nav {
    .is-selected {
      background-color: white;
      color: #000;
      border-left: none;
      > a {
        background-color: transparent;
        &:hover {
          background-color: white;
        }
      }
    }
  }
  