#{$global}-grid {
  &-col4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-col3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &--gap {
    column-gap: pxToRem(8);
  }
}
